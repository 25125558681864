import React, { useEffect, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { ImportExport, Search } from "@mui/icons-material";
import "./patientpage.css";
import AddNewPt from "./AddNewPt";
import { getAuth,signOut } from 'firebase/auth';

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth, db } from "../../firebaseConfig/firebase";
import Patientprofile from "./profile/Patientprofile";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { async } from "@firebase/util";

var now = new Date();
var today = new Date(now.getYear(), now.getMonth(), now.getDate());

function Patientapage() {

  const {user,logOut}= UserAuth()

  const signout =async()=>{
 
    signOut(auth)
    console.log('clicked')
    navigate('/login')
  }
  let navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    const patientProfile = collection(db, "patientProfile");
    const abc = inputText.toLowerCase().trim();
    //mounts
    
    const qd = query(patientProfile, where("namearray", "array-contains", abc),orderBy("created","desc"));

    const q = query(
      collection(db, "patientProfile"),
      orderBy("created", "desc"),
      limit(6)
    );

    const unsub = onSnapshot(inputText == "" ? q : qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),
          id:   doc.id,
          opdId:doc.get("ptId"),
          name: doc.get("name"),
          phoneNumber: doc.get("phNumber"),
          DOB: doc.get("DOB"),
          gender: doc.get("gender"),
          age: doc.get("age"),
         
          created: doc.get("created"),
          TotalAmount: doc.get("TotalAmount"),
          paid: doc.get("paid"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [inputText]);

  const today = () => {
    alert("today");
    var startOfToday = new Date(); 
    startOfToday.setHours(0,0,0,0);
    var endOfToday = new Date(); 
    endOfToday.setHours(23,59,59,999);




  };

  return (
    <div>
      <div className="searchBar">
        <TextField
          fullWidth
          label="Search by Name or PhoneNumber"
          id="fullWidth"
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
          onChange={(e) =>
            setTimeout(() => {
              setInputText(e.target.value);
            }, 1500)
          }
        />
      </div>
      <div></div>

      <div style={{ display: "flex" }}>
 
        <div className="rightBar">
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              color: "grey",
              justifyContent: "flex-end",
            }}
          >
            <p>Latest patients</p>
          </div>

          <div
            style={{
              width: "100%",
              // overflowY: "scroll",
              height: "85vh",
              // overflowX: "hidden",
            }}
          >
            {data.length === 0 ? (
              <div> Loading data ... </div>
            ) : (
              data.map((item) => (
                <div
                  className="patientBox"
                  onClick={() => {
                    navigate("/profile", {
                      state: {
                        id: 1,
                        ptid: item.id,
                        opdId: item.opdId,
                      
                        name: item.name,
                        TotalAmount: item.TotalAmount,
                        phoneNumber: item.phoneNumber,
                        DOB: item.DOB,
                        gender: item.gender,
                        age:item.age,
                        totalPaid: item.paid,
                      },
                    });
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        marginLeft: "10px",
                        color: "black",
                      }}
                    >
                      {item.name}
                    </p>

                    <a
                      href={`tel: ${item.phNumber}`}
                      style={{
                        color: "rgb(32, 84, 131)",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      <p
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      >
                        {item.phNumber}
                      </p>
                    </a>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {item.gender}{" "}
                      </p>
                    </div>

                    <p style={{ fontSize: "12px", color: "grey" }}>
                      {item.created == null
                        ? ""
                        : item.created.toDate().toDateString()}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patientapage;
