import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from "@mui/material/Stack";
import axios from "axios"

import { addDoc, collection, query, onSnapshot, doc,serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import { Navigate, useNavigate } from "react-router-dom";
import uniqid from 'uniqid';


// require("dotenv").config();


// let opdId1 = uniqid()



function AddNewPt() {
  let navigate = useNavigate();
  const [open, setOpen] =useState(false);
  const [date, setDate] = useState(new Date(Date.now()));
 const [opdId,setOpdId]=useState(uniqid())
  const [fName, setFname] = useState("");
  const [gender, setGender] = useState('female');

  const [phNumber, setPhNumber] = useState();
  const [age, setAge] = useState();
  const genderHandleChange = (event) => {
    setGender(event.target.value);
    event.preventDefault()
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {

    if (reason && reason == "backdropClick") 
    return;


    setOpen(false);
    
  };

  const addNewPtDB = async () => {
    const abc = fName + " "+ phNumber
    const newSplit = abc.split(' ')
    const newArray= [fName.toLowerCase()]
    
    for(let i=0; i < newSplit.length; i++){
      
        for( let y=1; y<newSplit[i].length+1; y++){
            
            newArray.push(newSplit[i].substring(0,y).toLowerCase())
      
        }
    }
    let datew = JSON.stringify(date);
    datew = datew.slice(1, 11);

    const profileData = {
      
      name: fName,
      namearray:newArray,
      phNumber: phNumber,
   ptId:opdId,
     
      gender:gender,
      age:age,
      paid:0,
      created:serverTimestamp()
    };


    
    const opdData = {
      gender: gender,

      phNumber: phNumber,

      ptId: opdId,
      ptName: fName,
      age:age,
      date:datew,
      ptCreatedDate:datew,
      created: serverTimestamp(),
    };


 





 
    await addDoc(collection(db, "opdEntry"), opdData);
    await addDoc(collection(db, "patientProfile"), profileData);
   


    axios.post("https://us-central1-patradentalclinic.cloudfunctions.net/expressApi/welcomeMsg", {
      name: fName,
      phno:phNumber,
    },
    {             
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      }
    }
    
    ).then((res)=>{
      console.log(res);
    }).catch((err)=>{
      
      console.log(err);
    })

 

    setFname();

    setPhNumber();
    setAge();
setGender();

setOpdId();
    handleClose();


    navigate("/opd")

  };

  return (
    <div>
      <Button onClick={handleClickOpen} style={{fontSize:"14px", width:'100%',color:"#30344d",}}>Add A New Patient</Button>
      <Dialog style={{}} fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>Add Patients</DialogTitle>
        <DialogContent>
          <DialogContentText>New Patient Registration</DialogContentText>
          <TextField
        
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setFname(value);
            }}
          />
            <TextField
            id="phNumber"
            label="WhatsApp Number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setPhNumber(value);
            }}
          />
           <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group" style={{marginBottom:'10px',marginTop:'10px'}}>Gender</FormLabel>

      <RadioGroup

style={{marginLeft:'10px'}}


        row
     
        name="row-radio-buttons-group"
    

        aria-labelledby="demo-controlled-radio-buttons-group"
     
        value={gender}
        onChange={genderHandleChange}
      >
        <FormControlLabel value="Male" control={<Radio />} label="Male" />
        <FormControlLabel value="Female" control={<Radio />} label="Female" />
      </RadioGroup>
    </FormControl>
  

    <TextField
        
        
        margin="dense"
        id="age"
        label="Age"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        fullWidth
        variant="standard"
        onChange={(event) => {
          const { value } = event.target;
          setAge(value);
        }}
      />

       
    
         
          
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            onClick={addNewPtDB}
            style={{ fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              padding: "3px",
              borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white",}}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewPt;
