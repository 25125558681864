import React, { useEffect, useState } from "react";
import "./homepage.css";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Padding, Search } from "@mui/icons-material";
import { TextField } from "@material-ui/core";
import Bottombar from "../Bottombar";
import {
  collection,
  getCountFromServer,
  limit,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebaseConfig/firebase";
import { useNavigate } from "react-router-dom";

function Homepage() {
  let navigate = useNavigate();

  const [count1, setcount] = useState();
  const [rctcount, setrctcount] = useState();
  const [opdcount, setopdcount] = useState();
  const [appointmentcount, setappointmentcount] = useState();

  const [date, setDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  const unsub = async () => {
    const AllCases = collection(db, "patientProfile");
    const treatments = collection(db, "treatment");
    const opdEntry = collection(db, "opdEntry");
    const rctCount = query(treatments, where("treatmentName", "==", "RCT"));
    const opdCount = query(opdEntry, where("date", "==", datew));
    const AppointmentDetails = collection(db, "AppointmentDetails");

    const AppointmentCount = query(
      AppointmentDetails,
      where("date", "==", datew)
    );

    const snapshotAll = await getCountFromServer(AllCases);
    const count = snapshotAll.data().count;
    setcount(count);

    const snapshotRCT = await getCountFromServer(rctCount);
    const countrct = snapshotRCT.data().count;
    setrctcount(countrct);

    const snapshotOPD = await getCountFromServer(opdCount);
    const countopd = snapshotOPD.data().count;
    setopdcount(countopd);

    const snapshotAppointment = await getCountFromServer(AppointmentCount);
    const countappointment = snapshotAppointment.data().count;
    setappointmentcount(countappointment);

    console.log(count);
  };
  unsub();

  return (
    <div className="homepage">
      <div
        className="topBar"
        style={{
          height: "300px",
          backgroundColor: "rgba(12, 17, 52, 0.973)",
          color: "white",
          width: "100%",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          // marginTop:'10px'
        }}
      >
        <div style={{ fontSize: "18px", paddingTop: "10px" }}>
          PATRA DENTAL CLINIC
        </div>
        <div style={{ fontSize: "12px", margin: "0px" }}>
          A Super Speciality Dental Clinic
        </div>

        <div className="topSection" style={{ margin: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div className="boxFront" style={{}}>
              <div
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
              >
                {count1}
              </div>
              <div style={{ marginTop: "16px" }}>
                Total Patients* <div style={{ fontSize: "6px" }}> Jan 23</div>
              </div>
            </div>
            <div
              className="boxFront"
              onClick={() => {
                navigate("/calender", {});
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
              >
                {appointmentcount}
              </div>
              <div style={{ marginTop: "16px" }}>
                APPOINTMENTS <div style={{ fontSize: "8px" }}> Today</div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "10px",
            }}
          >
            <div className="boxFront"     onClick={() => {
                navigate("/opd", {});
              }}>
              <div
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
              >
                {opdcount}
              </div>
              <div style={{ marginTop: "16px" }}>
                PATIENT VISITS <div style={{ fontSize: "8px" }}> Today</div>
              </div>
            </div>
            <div className="boxFront">
              <div
                style={{
                  color: "white",
                  fontSize: "30px",
                  fontWeight: "bolder",
                }}
              >
                {rctcount}
              </div>
              <div style={{ marginTop: "16px" }}>RCT</div>
            </div>
          </div>
        </div>
      </div>
    
      <div></div>
    </div>
  );
}

export default Homepage;
