import React from 'react'
import  { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControl from "@mui/material/FormControl";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

function OpdPay(props) {




  const [open, setOpen] = useState(false);
  const [price, setprice] = useState('');
  
  const [modeofPayment, setModeofPayment] = useState('CASH');



  const [treatmentName, setTreatmentName] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {

    if (reason && reason == "backdropClick") 
    return;
    setOpen(false);
    setModeofPayment()
  };



  const addNewTreatment = async () => {
    const patientAccount = {
      patientId: props.id,
      OpdId: props.opdId,
      created: serverTimestamp(),
      price: price,
     
    };

    const ptProfile = doc(db, "opdEntry", props.opdId);

   
    await updateDoc(ptProfile, {
      opdPay: price,
      status:"pending",
      paymentMode:modeofPayment,
      created: serverTimestamp(),
  })

  

    setTreatmentName();
   
    setprice();
    setModeofPayment()
    handleClose();
  };

  const handleChange = (event) => {
    setTreatmentName(event.target.value);
  };
  const modeOfPaymentHandleChange = (event) => {
    setModeofPayment(event.target.value);
    event.preventDefault()
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen} Addnumber
        style={{
          // lineHeight: "30px",
          // width: "26px",
          fontSize: "12px",
          fontWeight: "bold",
          borderRadius: "15px",
          border: "1px solid  rgb(5, 2, 43)",
          textAlign: " center",
          padding: "8px",
          backgroundColor: "rgba(12, 17, 52, 0.888)",
          color: "white",
        }}
      >
        Pay ₹ {props.askPrice}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> To Pay <div style={{color:'red', fontSize:'24px'}}>

        ₹ { props.askPrice} 
        </div> 
        </DialogTitle>
        <DialogContent>
          

          <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth>
 


              <TextField
            id="Total tooth" style={{width:'90%'}}
            label="Fees Paid"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setprice(value);
            }}
          />


<RadioGroup

style={{marginLeft:'10px'}}


        row
     
        name="row-radio-buttons-group"
    

        aria-labelledby="demo-controlled-radio-buttons-group"
     
        value={modeofPayment}
        onChange={modeOfPaymentHandleChange}
      >
        <FormControlLabel value="CASH" control={<Radio />} label="CASH" />
        <FormControlLabel value="G-PAY" control={<Radio />} label="G-PAY" />
      </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            onClick={addNewTreatment}
            style={{ fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              // padding: "3px",
              // borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white", }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OpdPay