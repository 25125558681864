import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useState, useEffect } from "react";

import { GoogleButton } from "react-google-button";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "./context/AuthContext";
import { auth } from "./firebaseConfig/firebase";

function LoginPage() {
  const { googleSignIn,user } = UserAuth();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if(user!=null ){
  //     navigate('/')
  //   }
  
   
  // }, [user])
  

  const handleGoogleSignIn = async () => {
    try {

     const provider = new GoogleAuthProvider();
     await  signInWithPopup(auth,provider)


     
      navigate('/')
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <GoogleButton onClick={handleGoogleSignIn}></GoogleButton>
    </div>
  );
}

export default LoginPage;
