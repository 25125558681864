import React from "react";
import { WhatsApp, AccountCircle, PersonPin } from "@mui/icons-material";
import axios from "axios";

const token = process.env.TOKEN;
const mytoken = process.env.MYTOKEN;
const phidtoken = process.env.phidtoken;


function Clinicpage() {
  return (
    <div>
      <div
        style={{
          height: "30px",
          backgroundColor: "green",
          color: "white",
          padding: "5px",display:'flex',alignItems:'center'
        }}
      >
        {" "}
        <WhatsApp />  WhatsApp zChat{" "}
      </div>
      <div style={{ margin: "2px" }}></div>
      <div
        style={{
          backgroundColor: "grey",
          fontSize: "20px",display:'flex',alignItems:'center'
        }}
      >
        {" "}
        <PersonPin sx={{ fontSize: 60 }} />
        <div>

        </div>
        Ram Babu
      </div>
    </div>
  );
}

export default Clinicpage;
