import { style } from "@mui/system";

import { Delete, DeleteOutline, Edit } from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { db } from "../../../firebaseConfig/firebase";
import AddNewTretment from "../AddNewTretment";
import "./patientProfile.css";
import rctImg from "../../../img/rct.jpg";
import implantImg from "../../../img/dental-implants.png";
import extractionImg from "../../../img/extraction.jpg";
import opdImg from "../../../img/opd.png";
import orthoImg from "../../../img/ortho.jpg";
import cappingImg from "../../../img/capping.jpg";
import dentureImg from "../../../img/dentures-icon.png";
import preventiveImg from "../../../img/preventive-dentistry.png";
import { WhatsApp, AccountCircle, PersonPin } from "@mui/icons-material";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import ProfileSubSec from "./ProfileSubSec";
import Payment from "../payment/Payment";

function Patientprofile() {
  let navigate = useNavigate();
  const state = useLocation();

  const [treatmentOpen, setTreatmentOpen] = useState(true);
  const [profileOpen, setProfileOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);

  const imgselection = (imgselection) => {
    if (imgselection === "Extraction") {
      return extractionImg;
    } 
    else if (imgselection === "Capping(M C)") {
      return cappingImg;
    }
    else if (imgselection === "RCT") {
      return rctImg;
    }
    else if (imgselection === "Capping(M C-IVO)") {
      return cappingImg;
    }
    else if (imgselection === "Capping(Zirconia)") {
      return cappingImg;
    }
    else if (imgselection === "Implants") {
      return implantImg;
    }
    else if (imgselection === "Surgery") {
      return rctImg;
    }
    else if (imgselection === "Filling") {
      return preventiveImg;
    }
    else if (imgselection === "3rd Molar") {
      return extractionImg;
    }
    else if (imgselection === "CD") {
      return dentureImg;
    }
    else if (imgselection === "Ortho") {
      return orthoImg;
    }else{
      return opdImg
    }
  };

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => ''}>
        <IconButton style={{ color: "rgb(5, 2, 43)", marginRight: "20px" }}>
          <Edit />
        </IconButton>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (tretmentId, treatmentName) => (
    <TrailingActions>
      <SwipeAction
        style={{ color: "red" }}
        onClick={async () => {
          const a = prompt(`Enter code to delete "${treatmentName}" `);
          if (a === "Yess") {
            await deleteDoc(doc(db, "treatment", tretmentId));
          } else {
            alert(`Failed to delete "${treatmentName}" `);
          }
        }}
      >
        <IconButton style={{ color: "red", marginRight: "20px" }}>
          <Delete />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  const [treatments, setTreatments] = useState([]);
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    const treatmentData = collection(db, "treatment");

    const paymentData = collection(db, "patientAccount");

    const tD = query(
      treatmentData,
      where("patientId", "==", state.state.opdId || state.state.ptid),
      orderBy("created", "desc")
    );


    const paymentQuery = query(

      paymentData,
      where("patientId", "==", state.state.opdId || state.state.ptid),
      orderBy("created", "desc")
    );






    const patientData = onSnapshot(tD, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          tretmentId: doc.id,
          patientId: doc.get("opdId"),
          treatmentName: doc.get("treatmentName"),
          treatmentPrice: doc.get("price"),
          treatmentPaid: doc.get("paid"),
          qty: doc.get("qty"),
          chiefComplaint:doc.get("chiefComplaint"),

          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setTreatments(newArray);
    });


    const paymentDataQuery = onSnapshot(paymentQuery, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          docId: doc.id,
          patientId: doc.get("patientId"),
          opdId: doc.get("opdId"),
          price: doc.get("price"),
      
          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setPayments(newArray);
    });

    setTreatmentOpen(true);

    return () => { paymentDataQuery()
      patientData();
    };
  }, []);

  const totalPaidAmount = payments
    .map((item) => parseInt(item.price))
    .reduce((prev, curr) => prev + curr, 0);
  const totalNetAmount = treatments
    .map((item) => parseInt(item.treatmentPrice))
    .reduce((prev, curr) => prev + curr, 0);

  
  const dueAmount = totalNetAmount - totalPaidAmount;

  return (
    <div>
      <div
        style={{
          margin: "5px",
          padding: "10px",
          backgroundColor: "rgba(10,13,37)",
          color: "white",
          borderRadius: "10px",
        }}
      >
        <div
          className="topSec"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{ fontWeight: "bold", fontSize: "16px", margin: "2px" }}
            >
              {state.state.name}
            </div>
            <div>
             

            </div>

            <p style={{ fontSize: "12px",marginLeft:"2px" }}>
            No Medical History
            {state.state.opdId}
            </p>
          
          </div>
          <div>
            <div>
              {state.state.age}  {state.state.gender}
            </div>
            {/* <div style={{ fontSize: "12px" }}>{state.state.DOB}</div> */}
            <a
              href={`tel:+91 ${state.state.phoneNumber}`}
              style={{
                textDecoration: "none",
              }}
            >
              {" "}
              <p
                style={{
                  fontSize: "14px",
                color: "white",

                  fontWeight: "bold",
                  marginRight: "5px",
                }}
              >
                {state.state.phoneNumber}
              </p>
            </a>

            {/* <p>{state.state.phoneNumber}</p> */}
          </div>
                  
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" }}>{totalNetAmount}</div>
            <div style={{ marginTop: "5px", color: "grey",fontSize: "12px" }}> Total</div>{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" }}>{totalPaidAmount}</div>
            <div style={{ marginTop: "5px", color: "grey",fontSize: "12px" }}> Paid</div>{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "14px" ,color: "red",}}>{dueAmount}</div>
            <div style={{ marginTop: "5px", color: "grey",fontSize: "12px" }}> Due</div>{" "}
          </div>
        </div>
        <div style={{margin:'5px'}}>delete patient</div>
        <div
          className="midSec"
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "rgb(55, 53, 73)",
            color: "white",
            borderRadius: "10px",
            padding: "10px",
            margin: "0px",
          }}
        >
          <div
            onClick={() => {
              setProfileOpen(false);
              setTreatmentOpen(true);
            }}
          >
            {" "}
            {treatmentOpen == true ? (
              <div style={{ fontWeight: "bold", fontSize: "14px",borderBottom:'1px solid grey' ,paddingBottom:'3px'}}>
                Treatments
              </div>
            ) : (
              <div style={{ fontSize: "14px" }}>Treatments </div>
            )}
          </div>

          <div
            onClick={() => {
              setTreatmentOpen(false);
              setProfileOpen(false);
              setPaymentOpen(true);
            }}
          >
            {paymentOpen == true ? (
              <div style={{ fontWeight: "bold", fontSize: "14px",borderBottom:'1px solid grey',paddingBottom:'3px'  }}>
                Payments
              </div>
            ) : (
              <div style={{ fontSize: "14px" }}>Payments </div>
            )}
          </div>
     
       

        </div>
      </div>

      <div style={{ overflow: "scroll", height: "65vh", width: "100%" }}>
        {treatmentOpen == true ? (
          <div>
            {treatments.length === 0 ? (
              <div> Loading data ... </div>
            ) : (
              treatments.map((item) => (
                <div className="lastSec">
                
                      <div style={{ width: "96%", position:"relative", height: "120px" }}>
                        <div
                          className="patientBox"
                          style={{
                            display: "flex",
                            borderLeft: "4px solid rgb(42,53,55)",
                            height:'90px'
                          }}
                          onClick={() => {
                            navigate("/treatment", {
                              state: {
                                id: state.state.ptid,
                                tId: item.tretmentId,
                                tName: item.treatmentName,
                                ptName: state.state.name,
                                ptPhNumber:state.state.phoneNumber,
                                tTotal: item.treatmentPrice,
                                tCreated: item.created.toDate().toDateString(),
                              },
                            });
                          }}
                        >
                          <div style={{height:'80px',width:'110px'}}>
                            <img
                              src={imgselection(item.treatmentName)}
                              width="100%"
                              height="80"
                            />
                          </div>
                          <div style={{ width: "100%",marginLeft:"8px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <div
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                {item.treatmentName}
                              </div>

                              <div
                                  style={{
                                    marginRight: "15px",
                                    display: "flex",
                                  }}
                                >
                                  {" "}
                                  <div style={{ fontSize: "12px",color:'grey' }}>
                                    Total:
                                  </div>
                                  <div
                                    style={{ color: "grey",fontWeight:'bold', fontSize: "14px" }}
                                  >
                                    {item.treatmentPrice}
                                  </div>
                                </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ fontSize: "12px", marginTop: "5px" }}
                              >
                              
                              </div>
                              <div style={{ fontSize: "14px" }}>
                                <div
                                  style={{
                                    marginRight: "15px",
                                    display: "flex",
                                  }}
                                >
                                  {" "}
                                  <div style={{ fontSize: "12px",color:'grey' }}>
                                   
                                  </div>
                                  <div
                                    style={{ color: "green", fontSize: "14px" }}
                                  >
                                 
                                  </div>
                                </div>

                          
                              </div>
                            </div>

                            <div
                              className="sec3"
                              style={{
                                color: "grey",
                                fontSize: "14px",
                                marginTop: "15px",
                              }}
                            >
                         Qty:    {item.qty} 
                            </div>
                            <div
                              className="sec3"
                              style={{
                                color: "grey",
                                fontSize: "14px",marginTop:'2px'
                               
                              }}
                            >
                       
                            </div>
                          </div>{" "}
                        </div>
                        <div
                                className="sec1"
                                style={{
                                  position:"absolute",
                                  right: 0,

                                  color: "rgba(169, 169, 169,0.655)",
                                  fontSize: "12px",
                                }}
                              >
                                {item.created == null
                                  ? ""
                                  : item.created.toDate().toDateString()}
                              </div>
                      </div>
                      
                      
                  
                </div>
              ))
            )}
          </div>
        ) : (
          <div><Payment paymentData={payments}  /></div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: "0px",
          alignItem: "center",
          justifyContent: "flex-end",
          // width: "90%",
          marginBottom: "100px",
         right:'10px',
          zIndex: "999",
          color: "red",
        }}
      >
     
      </div>
    </div>
  );
}

export default Patientprofile;
