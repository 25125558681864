import React from "react";

function Labpage() {
  return <div>
s



  </div>;
}

export default Labpage;
