import React, { useEffect, useState } from "react";
import StartNewOpd from "./StartNewOpd";
import "./opd.css";

import { TextField } from "@material-ui/core";
import { Delete, DeleteOutline, Edit } from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
import Bottombar from "../Bottombar";
import OpdPay from "./OpdPay";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import { async } from "@firebase/util";
import Addnumber from "./Addnumber";
function Opd() {
  const [open, setOpen] = useState(false);
  const [opdidC, setOpdId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseYES = async () => {
    setShowAlert(true);
    await deleteDoc(doc(db, "opdEntry", opdidC));

    setTimeout(() => setShowAlert(false), 2000);

    setOpen(false);
  };

  const [date, setDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  let navigate = useNavigate();
  const state = useLocation();

  const [inputText, setInputText] = useState("");
  const [data, setData] = useState([]);

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction onClick={() => ""}>
        <IconButton style={{ color: "rgb(5, 2, 43)", marginRight: "20px" }}>
          <Edit />
        </IconButton>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (opdID, opdPtName, status) => (
    <TrailingActions>
      <SwipeAction
        style={{ color: "red" }}
        onClick={async () => {
          if (status != "approved") {
            setOpdId(opdID);
            setOpen(true);
          }

          //           setShowAlert(true)
          //             await deleteDoc(doc(db, "opdEntry", opdID));

          // setTimeout(()=>setShowAlert(false),2000)
        }}
      >
        <IconButton style={{ color: "red", marginRight: "20px" }}>
          <Delete />
        </IconButton>
      </SwipeAction>
    </TrailingActions>
  );

  useEffect(() => {
    const opdData = collection(db, "opdEntry");
    const abc = inputText.toLowerCase();
    //mounts

    const qd = query(
      opdData,
      where("date", "==", datew),
      orderBy("created", "desc")
    );

    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          opdId: doc.id,

          id: doc.get("ptId"),
          name: doc.get("ptName"),
          opdPay: doc.get("opdPay"),
          phoneNumber: doc.get("phNumber"),
          paymentMode: doc.get("paymentMode"),
          gender: doc.get("gender"),
          askPrice: doc.get("askPrice"),
          date: doc.get("date"),
          tokenNumber: doc.get("tokenNumber"),
          age: doc.get("age"),
          ptCreatedDate: doc.get("ptCreatedDate"),
          totalPaid: doc.get("totalPaid"),
          TotalAmount: doc.get("TotalAmount"),
          status: doc.get("status"),
          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [datew]);

  const [showAlert, setShowAlert] = useState(false);

  return (
    <div>
      <div>
        {showAlert == true ? (
          <Stack
            sx={{ width: "100%", position: "absolute", zIndex: "999" }}
            spacing={2}
          >
            <Alert severity="error">
              <AlertTitle>Deleted</AlertTitle>
              Patient Deleted from — <strong>OPD</strong>
            </Alert>
          </Stack>
        ) : (
          <div></div>
        )}
        <div
          className="topSec"
          style={{
            backgroundColor: "rgb(5, 2, 43)",
            // borderRadius: "30px",
            height: "5vh",
            // margin: "10px",
            color: "white",
            padding: "15px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ fontSize: "12px", color: "grey" }}>
            Date
            <div style={{ fontSize: "16px", color: "white" }}>{datew}</div>
          </div>
          <div style={{ fontWeight: "bolder", fontSize: "18px" }}>OPD</div>

          <div>
            <TextField
              style={{
                color: "red",
                backgroundColor: "white",
                border: "1px solid white",
                borderRadius: "15px",
                width: "100px",
                margin: "-5px",
              }}
              id="date"
              label="Calender"
              type="date"
              fullWidth
              defaultValue={Date.now()}
              onChange={(event) => setDate(event.target.value)}
              // sx={{ width: "100%" }}
            />
          </div>
        </div>{" "}
        <div
          className="midSec"
          style={{
            height: "80vh",

            backgroundColor: "white",
            // borderRadius: "30px",
            borderTop: "6px solid rgb(231, 233, 251)",
          }}
        >
          <div
            className="component"
            style={{
              overflow: "scroll",
              height: "75vh",
              width: "100%",
              overflowX: "hidden",
            }}
          >
            {data.map((item) => (
              <div
                style={{
                  margin: "5px",
                  borderBottom: "1px dotted grey",
                  paddingBottom: "8px",

                  // height: "70px",
                }}
              >
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Want to Delete from {}OPD
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Press Yes to Delete <br></br> Press NO to Cancel
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} style={{ color: "red" }}>
                      NO
                    </Button>
                    <Button
                      onClick={handleCloseYES}
                      style={{
                        fontSize: "16px",
                        // fontWeight: "bold",
                        // border: "1px solid ",
                        padding: "3px",
                        borderRadius: "5px",
                        backgroundColor: "rgb(8,10,48,0.9)",
                        color: "white",
                      }}
                      autoFocus
                    >
                      YES
                    </Button>
                  </DialogActions>
                </Dialog>

                <SwipeableList>
                  <SwipeableListItem
                    leadingActions={leadingActions(
                      item.opdId,
                      item.name,
                      item.status
                    )}
                    trailingActions={trailingActions(
                      item.opdId,
                      item.name,
                      item.status
                    )}
                  >
                    <div style={{ width: "98%" }}>
                      <div>
                        <div
                          style={{}}
                          onClick={() => {
                            navigate("/profile", {
                              state: {
                                id: item.id,
                                opdId: item.id,
                                ptid: item.id,
                                name: item.name,

                                phoneNumber: item.phoneNumber,
                                age: item.age,

                                gender: item.gender,
                                totalPaid: item.paid,
                              },
                            });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  padding: "8px",
                                  backgroundColor: "#ebeef2",
                                  borderRadius: "15px",
                                }}
                              >
                                {item.tokenNumber}
                              </div>

                              <div>
                                <div
                                  style={{ fontSize: "16px", margin: "8px" }}
                                >
                                  {item.ptName}
                                  <div style={{ fontSize: "12px" }}>
                                    {item.age}/ {item.gender}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                {" "}
                                <a
                                  href={`tel: ${item.phNumber}`}
                                  style={{
                                    color: "rgb(32, 84, 131)",
                                    textDecoration: "none",
                                  }}
                                >
                                  {" "}
                                  <p
                                    style={{
                                      fontSize: "14px",

                                      fontWeight: "bold",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {item.phNumber}
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        ><div style={{marginLeft:'20px'}}>
                            <Addnumber opdId={item.opdId}> </Addnumber>
                          </div>
                          <div
                            style={{
                              marginRight: "10px",
                              color: "grey",
                              fontSize: "16px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <div>Paid: {item.opdPay}</div>
                            <div>{item.paymentMode}</div>
                          </div>
                          {item.status === "approved" ? (
                            <Button
                              style={{
                                // lineHeight: "30px",
                                // width: "26px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "15px",
                                border: "1px solid  rgb(5, 2, 43)",
                                textAlign: " center",
                                padding: "1px",

                                color: "red",
                              }}
                            >
                              PAID
                            </Button>
                          ) : (
                            <OpdPay
                              id={item.id}
                              opdId={item.opdId}
                              askPrice={item.askPrice}
                              status={item.status}
                            />
                          )}

                   
                          
                        </div>

                        <div> </div>
                      </div>
                    </div>
                  </SwipeableListItem>
                </SwipeableList>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: "0px",
          alignItem: "center",
          justifyContent: "center",

          marginBottom: "90px",
          width: "100%",
          zIndex: "999",
        }}
      >
        <div
          style={{
            color: "white",
            backgroundColor: "rgba(12, 17, 52, 0.973)",
            padding: "12px",
            borderRadius: "15px",
          }}
          onClick={() => {
            navigate("/newopdstart", {
              state: {
                id: "1",
              },
            });
          }}
        >
          Start OPD
        </div>
      </div>

      {/* <Bottombar/> */}
    </div>
  );
}

export default Opd;
