import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './components/Bottombar'
import Bottombar from './components/Bottombar';
import Homepage from './components/home/Homepage';
import Patientapage from './components/patients/Patientapage';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Labpage from './components/lab/Labpage';
import Clinicpage from './components/clinic/Clinicpage';
import Patientprofile from './components/patients/profile/Patientprofile';
import AddNewPt from './components/patients/AddNewPt';
import Calender from './components/calender/Calender';
import Treatmentpage from './components/patients/profile/Treatmentpage';
import AddNewTretment from './components/patients/AddNewTretment';
import Opd from './components/opd/Opd';
import StartNewOpd from './components/opd/StartNewOpd';
import { getAuth,signOut } from 'firebase/auth';
import { auth,provider} from './firebaseConfig/firebase';
import LoginPage from './LoginPage';
import { AuthContextProvider } from './context/AuthContext';
import Protected from './context/Protected';
import Payment from './components/patients/payment/Payment';


function App(props) {


 


 
  return (
    <div className="App">


<AuthContextProvider>



<BrowserRouter>

    
<Routes>
<Route exact path="/login" element={<LoginPage/>} />
<Route exact path="/" element={ <Protected>  <Homepage/>  </Protected> } />
<Route exact path="/patients" element={ <Protected>     <Patientapage/>      </Protected>  } />
<Route exact path="/lab" element={ <Protected>       <Labpage/>     </Protected> } />
<Route exact path="/profile" element={ <Protected>       <Patientprofile/>    </Protected>  } />
<Route exact path="/calender" element={ <Protected>         <Calender/>  </Protected>  } />
<Route exact path="/clinic" element={ <Protected>         <Clinicpage/>  </Protected>  } />
<Route exact path="/clinic" element={ <Protected>         <AddNewPt/>   </Protected> } />
<Route exact path="/newtretment" element={ <Protected>        <AddNewTretment/>   </Protected>  } />
<Route exact path="/opd" element={ <Protected>       <Opd/>    </Protected>  } />
<Route exact path="/newopdstart" element={ <Protected>     <StartNewOpd/>       </Protected> } />
<Route exact path="/treatment" element={ <Protected>       <Treatmentpage/>    </Protected>  } />
<Route exact path="/paymentP" element={ <Protected>       <Payment/>    </Protected>  } />
 
</Routes>   
{/* <Routes>
<Route exact path="/login" element={<LoginPage/>} />
<Route exact path="/" element={  <Homepage/>   } />
<Route exact path="/patients" element={     <Patientapage/>        } />
<Route exact path="/lab" element={       <Labpage/>      } />
<Route exact path="/profile" element={       <Patientprofile/>      } />
<Route exact path="/calender" element={         <Calender/>    } />y
<Route exact path="/clinic" element={         <Clinicpage/>    } />
<Route exact path="/clinic" element={         <AddNewPt/>    } />
<Route exact path="/newtretment" element={        <AddNewTretment/>     } />
<Route exact path="/opd" element={       <Opd/>      } />
<Route exact path="/newopdstart" element={     <StartNewOpd/>        } />
<Route exact path="/treatment" element={       <Treatmentpage/>      } />
<Route exact path="/paymentP" element={       <Payment/>      } />
 
</Routes>    */}


{/* {currentUserName} */}
<Bottombar></Bottombar>


    </BrowserRouter>
     
  
</AuthContextProvider>
    
    </div>
  );
}

export default App;
