import React, { useState } from "react";
import "./bottombar.css";
import { NavLink } from "react-router-dom";
import {
  HomeOutlined,
  Medication,
  PersonSearch,
  Construction,
  WhatsApp,
  Message,
  CalendarMonth,
  Delete,
  AirlineSeatReclineNormal
} from "@mui/icons-material";

function Bottombar() {



  const navLinkStyle = ({ isActive }) => {
    return {
      color: isActive ? "white" : "grey",
      textDecoration: "inherit",
      
    };
  };
  return (
    <div className="bottombar">
      <NavLink to="/" style={navLinkStyle}>
      
          <div
            className="icon_Name"
            style={{
              padding: "2px",
           

            alignItems: "center",
            display:'flex',
            flexDirection:'column',
           
       
            }}
          >
            <HomeOutlined />
            <p style={{ margin: "0px", fontSize: "10px" }}>
              {" "}
              Home
            </p>
          </div>
      
      </NavLink>
      <NavLink
        to="/opd"
        style={navLinkStyle}
      >
        <div
          className="icon_Name"
          style={{
            padding: "2px",
           

            alignItems: "center",
            display:'flex',
            flexDirection:'column',
           
      
          }}
        >
          <AirlineSeatReclineNormal />
          <p style={{ margin: "0px", fontSize: "10px" }}>OPD</p>
        </div>
      </NavLink>

      <NavLink
        to="/calender"
        style={navLinkStyle}
      >
        <div
          className="icon_Name"
          style={{
            padding: "2px",
           

           alignItems: "center",
           display:'flex',
           flexDirection:'column',
          
       
          }}
        >
          <CalendarMonth />
          <p style={{ margin: "0px", fontSize: "10px" }}>
            {" "}
            Appointment
          </p>
        </div>
      </NavLink>
      <NavLink
        to="/patients"
        style={navLinkStyle}
      >
        <div
          className="icon_Name"
          style={{
            padding: "2px",
           

           alignItems: "center",
           display:'flex',
           flexDirection:'column',
          
       
          }}
        >
          <PersonSearch />
          <p style={{ margin: "0px", fontSize: "10px" }}>
            {" "}
            Patients
          </p>
        </div>
      </NavLink>

  


    </div>
  );
}

export default Bottombar;
