import React from 'react'
import  { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControl from "@mui/material/FormControl";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

function Addnumber(props) {




  const [open, setOpen] = useState(false);
  const [tokenNumber, setTokenNumber] = useState('');
  


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {

    if (reason && reason == "backdropClick") 
    return;
    setOpen(false);
    
  };



  const addNewTreatment = async () => {
  

    const ptProfile = doc(db, "opdEntry", props.opdId);

   
    await updateDoc(ptProfile, {
      tokenNumber: tokenNumber,
     
  })

  

    
    handleClose();
  };



  return (
    <div>
      <Button
        onClick={handleClickOpen} 
        style={{
                            //   marginLeft: "-20px",
                            //   height: "15px",
                            //   width: "15px",
                              border: "3px solid white",
                              padding: "15px",
                              borderRadius: "100%",
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#ebeef2",
                              alignItems: "center",
                              fontWeight:'bolder'
                            //   fontSize: "26px",
                            }}
      >
        +
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Token Number <div style={{color:'red', fontSize:'20px'}}>

        </div> 
        </DialogTitle>
        <DialogContent>
          

          <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth>
 


              <TextField
            id="Total tooth" style={{width:'90%'}}
            label="Add Token Number"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            
            variant="standard"
            onChange={(event) => {
              const { value } = event.target;
              setTokenNumber(value);
            }}
          />


            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "red" }}>
            Cancel
          </Button>
          <Button
            onClick={addNewTreatment}
            style={{ fontSize: "16px",
              // fontWeight: "bold",
              // border: "1px solid ",
              // padding: "3px",
              // borderRadius: "5px",
              backgroundColor: "rgb(8,10,48,0.9)",
              color: "white", }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Addnumber