import React from "react";
import { useContext, createContext, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebaseConfig/firebase";
import { useEffect } from "react";
const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {


  const [user,setUser]= useState({})

const googleSignIn=()=>{
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth,provider)
}

const logout=()=>{
  signOut(auth)
}


useEffect(() => {
  const unSub= onAuthStateChanged(auth,(currentUser)=>{
    setUser(currentUser)
    console.log(currentUser)
  })
  return () => {
    unSub()
  }
}, [])


  return <AuthContext.Provider value={{googleSignIn,logout,user}}>{children}</AuthContext.Provider>;
};
export const UserAuth = () => {
  return useContext(AuthContext);
};
