import React from "react";

function Payment({ paymentData }) {
  const newArry = paymentData.map((a) => a);

  // console.log(newArry[0].price);

  

  return (
    <div style={{ overflow: "scroll", height: "65vh", width: "100%" }}>
      

      {/* {!paymentData[0].price ? <div>yes</div> : <div> nod</div>} */}
      {paymentData.map((a) => (

!a.price? <div style={{  width: "96%", position:"relative", height: "120px", borderLeft: "4px solid rgb(42,53,55)",
                         }}>no data
                        
                        </div>:

        <div style={{  width: "96%", position:"relative", height: "120px", borderLeft: "4px solid rgb(42,53,55)",
                         }}>
        <div>{a.price   }</div>
        <div>{a.created.toDate().toLocaleTimeString('en-US')}</div>
        <div>{a.created.toDate().toDateString()}</div>
        </div> 
      ))}
     
    </div>
  );
}

export default Payment;
