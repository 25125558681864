import { IconButton, TextField } from "@material-ui/core";
import { Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import {
  addDoc,
  collection,
  query,
  onSnapshot,
  doc,
  where,
  orderBy,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

import { useNavigate } from "react-router-dom";
import AddNewPt from "../patients/AddNewPt";

function StartNewOpd() {
  let navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [searchData, setSearchData] = useState(inputText == "" ? false : true);
  const [data, setData] = useState([]);
  const [newptdata, setNewptData] = useState([]);
  const [date, setDate] = useState(new Date(Date.now()));

  const startOpd = async (
    id,
    name,
    phNumber,
    gender,
    age,
    TotalAmount,
    totalPaid,
    ptCreatedDate
  ) => {
    let datew = JSON.stringify(date);
    datew = datew.slice(1, 11);




    const timestamp = Timestamp.fromMillis(ptCreatedDate.seconds * 1000 + ptCreatedDate.nanoseconds / 1000000);
    const dater = timestamp.toDate();
    
    // Extract year, month, and day components
    

    const year = dater.getFullYear();
const month = String(dater.getMonth() + 1).padStart(2, '0'); // Adding 1 since getMonth() returns 0-11
const day = String(dater.getDate()).padStart(2, '0');

// Create the formatted date string
const ptCreatedDateNew = `${year}-${month}-${day}`;




    const opdData = {
      gender: gender,

      phNumber: phNumber,
      age: age,
      ptId: id,
      ptName: name,

      date: datew,
      ptCreatedDate:ptCreatedDateNew,
      created: serverTimestamp(),
    };

    setShowAlert(true);
    await addDoc(collection(db, "opdEntry"), opdData);

    setShowAlert(false);

    navigate("/opd");
  };
  useEffect(() => {
    const patientProfile = collection(db, "patientProfile");
    const abc = inputText.toLowerCase().trim();
    //mounts

  
    const qd = query(patientProfile, where("namearray", "array-contains", abc),orderBy("created","desc"));


    const q = query(
      collection(db, "patientProfile"),
      orderBy("created", "desc"),
      limit(3)
    );
    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),
          id: doc.id,
          opdId: doc.get("ptId"),

          name: doc.get("name"),
          phoneNumber: doc.get("phNumber"),
          age: doc.get("age"),
          gender: doc.get("gender"),

          ptCreatedDate: doc.get("created"),
          TotalAmount: doc.get("TotalAmount"),
          paid: doc.get("paid"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [inputText]);

  const today = () => {
    alert("today");
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
  };
  const [showAlert, setShowAlert] = useState(false);

  return (
    <div>
      <div
        style={{
          color: "white",
          height: "50px",
          backgroundColor: "rgba(12, 17, 52, 0.73)",
          width: "100%",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{}}>Start a new OPD</div>
      </div>

      <div className="searchBar">
        {showAlert == true ? (
          <Stack
            sx={{ width: "100%", position: "absolute", zIndex: "999" }}
            spacing={2}
          >
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Patient added to — <strong>OPD</strong>
            </Alert>
          </Stack>
        ) : (
          <div></div>
        )}

        <div style={{ marginTop: "10px" }}> </div>

        <TextField
          autoFocus
          fullWidth
          label="Search by Name or PhoneNumber"
          id="outlined-basic"
          InputProps={{
            endAdornment: (
              <IconButton>
                <Search />
              </IconButton>
            ),
          }}
          onChange={(e) => {
            setSearchData(true);
            setTimeout(() => {
              setSearchData(false);
              setInputText(e.target.value);
            }, 1500);
          }}
          variant="outlined"
        />
      </div>

      <div style={{ display: "flex", width: "100%" }}>
        <div className="rightBa" style={{ width: "100%" }}>
          <div
            style={{
              width: "100%",
              overflowY: "scroll",
              height: "75vh",
              overflowX: "hidden",
            }}
          >
            {searchData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                  padding: "10px",
                  flexDirection: "column",
                }}
              >
                <div>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        padding: "10px",
                        flexDirection: "column",
                      }}
                    >
                      Searching...
                    </div>
                  </Box>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {inputText ? (
                  <div>
                    <div
                      style={{
                        color: " white",
                        // backgroundColor: "white",
                        padding: "5px",
                        borderRadius: "10px",
                        textAlign: "center",
                        marginTop: "10px",

                        borderBottom: "2px solid rgba(12, 17, 52, 0.973)",
                        // borderTop:'1px solid rgba(12, 17, 52, 0.973)',

                        padding: "15px",

                        boxShadow: "3px 2px 5px 2px #e9ebfa",
                      }}
                    >
                      <AddNewPt />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {data.map((item) => (
                  <div
                    className="patientBox1"
                    onClick={() =>
                      startOpd(
                        item.opdId || item.id,
                        item.name,
                        item.phoneNumber,
                        item.gender,
                        item.age,
                        item.TotalAmount,
                        item.paid,
                        item.ptCreatedDate
                      )
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        height: "200px",
                      }}
                    ><div style={{display :'flex'}}>
                      <div
                        style={{
                          fontSize: "16px",
                          marginLeft: "10px",
                          color: "black",
                        }}
                      >
                        {item.name}
                      </div>
                        <div style={{ fontSize: "12px", margin:'4px' }}>
                          {item.age}/ {item.gender}
                        </div>
                      </div>

                      <a
                        href={`tel: ${item.phNumber}`}
                        style={{
                          color: "rgb(32, 84, 131)",
                          textDecoration: "none",
                          marginRight: "10px",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            fontSize: "12px",

                            // fontWeight: "bold",
                            marginRight: "10px",
                          }}
                        >
                          {item.phNumber}
                        </p>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartNewOpd;
