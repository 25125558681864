import React, { useEffect, useState } from "react";

import { ListItem, TextField } from "@material-ui/core";
import {
  Delete,
  DeleteOutline,
  Edit,
  InsertEmoticon,
} from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";
//

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { WhatsApp, Message, Phone ,AccessTime} from "@mui/icons-material";
// import AccessTimeIcon from '@mui/icons-material/AccessTimeFilledO';

import "react-swipeable-list/dist/styles.css";




function Calender() {
  const [open, setOpen] = useState(false);
  const [opdidC, setOpdId] = useState("");



  const [date, setDate] = useState(new Date(Date.now()));
  let datew = JSON.stringify(date);
  datew = datew.slice(1, 11);

  let navigate = useNavigate();
  const state = useLocation();


  const [data, setData] = useState([]);




  useEffect(() => {
    const appointmentData = collection(db, "AppointmentDetails");

    //mounts

    const qd = query(
      appointmentData,
      where("date", "==", datew),
      orderBy("time", "asc")
    );

    const unsub = onSnapshot(qd, (snap) => {
      let newArray = [];

      snap.forEach((doc) => {
        newArray.push({
          ...doc.data(),

          id: doc.get("patientId"),
          
          ptName: doc.get("name"),
          docid: doc.get("docid"),
          timeString: doc.get("timeString"),

          phoneNumber: doc.get("phNumber"),
          tName: doc.get("reason"),
          appointmentDate: doc.get("date"),
          appointmentTime: doc.get("time"),

          created: doc.get("created"),
        });
      });

      console.log(newArray);

      setData(newArray);
    });

    return () => {
      unsub();
    };
  }, [datew]);





  return (
    <div>
      <div>
     
        <div
          className="topSec"
          style={{
            backgroundColor: "rgb(5, 2, 43)",
            // borderRadius: "30px",
            height: "5vh",
            // margin: "10px",
            color: "white",
            padding: "15px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ fontSize: "12px", color: "grey" }}>
            Date
            <div style={{ fontSize: "16px", color: "white" }}>{datew}</div>
          </div>
          <div style={{ fontWeight: "bolder", fontSize: "18px" }}>
            Appointment
          </div>

          <div>
            <TextField
              style={{
                color: "red",
                backgroundColor: "white",
                border: "1px solid white",
                borderRadius: "15px",
                width: "100px",
                margin: "-5px",
              }}
              id="date"
              label="Choose Date"
              type="date"
              fullWidth
              defaultValue={Date.now()}
              onChange={(event) => setDate(event.target.value)}
              // sx={{ width: "100%" }}
            />
          </div>
        </div>{" "}
        <div
          className="midSec"
          style={{
            height: "80vh",

            backgroundColor: "white",
            // borderRadius: "30px",
            borderTop: "6px solid rgb(231, 233, 251)",
          }}
        >
          <div
            className="component"
            style={{
              overflow: "scroll",
              height: "75vh",
              width: "100%",
              overflowX: "hidden",
            }}
          >
            {data.map((item) => (
              <div>
                <div
                  style={{
                    margin: "5px",
                    borderBottom: "1px dotted grey",
                    paddingBottom: "8px",

                    // height: "70px",
                  }}
                >
                  <div
                    style={{
                      width: "98%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{}}
                      onClick={() => {
                        navigate("/profile", {
                          state: {
                            docid:item.docid,
                            id: item.id,
                            ptid: item.id,
                            name: item.ptName,

                            phoneNumber: item.phoneNumber,
                          },
                        });
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: "8px",
                              display:'flex',
                             
                            }}
                          > <AccessTime>
</AccessTime>
<div    style={{
                            
                              color:'rgba(12, 17, 52, 0.973)', marginLeft:'5px'
                            }}>
                            {item.timeString}
</div>

                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              // fontWeight: "bold",
                              margin: "8px",
                            }}
                          >
                            {item.ptName}
                          </div>
                          <div style={{ fontSize: "12px", margin: "8px" ,color:'grey'}}>
                            {item.tName}
                          </div>
            
                        </div>
                      </div>
                    </div>

                    <div style={{ color: "grey", fontSize: "08px" }}>
                

                    


                      <Button
              
          
              style={{
                          backgroundColor: "rgba(12, 17, 52, 0.973)",
                          borderRadius: "5px",
                          color: "white",
                         
                          display: "flex",
                          height:'35px'
                        }}
              fullWidth
        
              
            > 
                        <Phone></Phone>
                        <a
                              href={`tel: ${item.phoneNumber}`}
                              style={{
                                color: "white",
                                textDecoration: "none",
                                marginLeft:'5px'
                              }}
                            >
                              {" "}
                              <p
                                style={{
                                  // fontSize: "14px",

                                  marginRight: "10px",
                                }}
                              >
                                {item.phoneNumber}
                              </p>
                            </a>
            </Button>

                 
                 
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      {/* <Bottombar/> */}
    </div>
  );
}

export default Calender;