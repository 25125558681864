import { initializeApp } from "firebase/app";
import {getFirestore, addDoc,collection} from "firebase/firestore"
import { getAuth, GoogleAuthProvider,signInWithPopup } from "firebase/auth";
import { Error } from "@mui/icons-material";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCg8mV0HsuurnRGWZAeeBnGrEsqgK61M1o",
  authDomain: "patradentalclinic.firebaseapp.com",
  projectId: "patradentalclinic",
  storageBucket: "patradentalclinic.appspot.com",
  messagingSenderId: "179222951179",
  appId: "1:179222951179:web:78e0133f5b991520be1e0e",
  measurementId: "G-KCGSV189MP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db= getFirestore(app);
export const auth =getAuth(app)




export{db};